<template>
    <div class="calendar-day-sb-container">
        <div class="calendar-day-sb-header">
            <b>{{heading}}</b>
            <p>
                <span class="dose-color"
                    :style="{background: getCalendarColor(doseType)}"/>{{ $t(getCodeToConfigMap[doseType].legendText) }}
            </p>
        </div>
        <div v-if="items.length > 0" class="calendar-day-sb-content">
            <hr>
            <ul class="list-group">
                <li v-for="(item, i) in items" :key="i" class="list-group-item">{{item}}</li>
            </ul>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'CalendarDaySpeechBubbleComponent',
  props: {
    items: [],
    heading: String,
    doseType: String
  },
  computed: {
    ...mapGetters('Adherence', ['getCodeToConfigMap', 'accessibilityCodeToColorMap']),
    ...mapGetters(['accessibilityModeState'])
  },
  methods: {
    getCalendarColor (code) {
      if (this.accessibilityModeState) {
        return this.accessibilityCodeToColorMap[code]
      }
      return this.getCodeToConfigMap[code].design.color
    }
  }
}
</script>

<style lang='scss' scoped>
.calendar-day-sb-header {
    padding:10px 15px 10px 15px;
    font-size: 16px;
    font-weight: 500;
}
.calendar-day-sb-header p {
    color: var(--text-dark-background-color);
    font-size: 14px;
    margin:0;
    padding:0;
}

.calendar-day-sb-content ul {
    padding: 0 !important;
    margin: 0;
    list-style: none;
}
.calendar-day-sb-content ul li{
    padding:8px 15px 8px 15px;
    background-color: transparent;
    border: none;
}

.dose-color {
    display:inline-block;
    width:10px;
    height:10px;
    border-radius: 20px;
    margin-right: 5px;
}
hr {
    height: 1px;
    border: none;
    background-color: $primaryLight;
}
</style>
