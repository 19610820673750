<template>
  <div class="calendar-legend">
    <div class="legend-info"
        v-for="(item, i) in combineLegend"
        :key="i"
        @mouseenter="$emit('legend-mouseenter', [item.code])"
        @mouseleave="$emit('legend-mouseleave')"
        @click="$emit('legend-mouseclick', [item.code])">
        <div
            class="legend-color"
            :style="{background: getCalendarColor(item.code)}">
            <font-awesome-icon
              v-if="item.design.icon !== null && item.design.icon !== undefined"
              class="legend-icon"
              :icon="item.design.icon" />
        </div>
        <p>{{$t(item.legendText)}} <span class="code-count" v-if="showCounts">({{getDigitalCount(item.code)}})</span></p>
      </div>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import { adherenceCodeOrder, weeklyDosageEnabledDeployments, weeklyDosageEnabledAdherenceTechs } from '../../../../../../constants'

export default {
  name: 'CalendarLegend',
  data () {
    return {
      legend: {}
    }
  },
  props: {
    showCounts: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('Patient', ['patientData']),
    ...mapGetters('Adherence', ['getCodeToConfigMap', 'accessibilityCodeToColorMap']),
    ...mapGetters(['accessibilityModeState']),
    ...mapState('UnifiedPatient', ['techOptions']),
    ...mapState(['deploymentCode']),
    getAdherenceData () {
      return this.$store.getters['Adherence/adherenceData']
    },
    codeConfig () {
      return this.$store.getters['Adherence/adherenceCodeConfig']
    },
    combineLegend () {
      let techsEnabled = []
      if (this.patientData != null && this.patientData.episodeData != null && this.patientData.episodeData.stageData.monitoringMethod != null) {
        techsEnabled = [this.patientData.episodeData.stageData.monitoringMethod.toLowerCase()]
      } else if (this.techOptions != null) {
        techsEnabled = this.techOptions.map(tech => tech.key.toLowerCase())
      }
      const commonCodes = ['8', '6', '9', '2']
      const additionalCodeByTech = {
        '99dots': ['A', '4', '5', 'B'],
        '99dotslite': ['4', '5'],
        vot: ['3', '4'],
        merm: ['4']
      }
      let additionalCodes = []
      Object.keys(additionalCodeByTech).forEach(tech => {
        if (techsEnabled.includes(tech) && additionalCodeByTech[tech]) {
          const techAdditionalCodes = additionalCodeByTech[tech].filter(code => !additionalCodes.includes(code))
          additionalCodes = additionalCodes.concat(techAdditionalCodes)
        }
      })

      const codesToShow = commonCodes.concat(additionalCodes)

      if (weeklyDosageEnabledDeployments.includes(this.deploymentCode) &&
        weeklyDosageEnabledAdherenceTechs.filter(tech => techsEnabled.includes(tech)).length > 0
      ) {
        codesToShow.push('C')
      }

      const shortLegend = []
      for (const config of this.codeConfig) {
        if (codesToShow.includes(config.code)) {
          shortLegend.push({ ...config })
        }
      }

      // sorting legends in required order as per https://gitlab.com/everwell/platform/-/issues/12130
      shortLegend.sort((a, b) => adherenceCodeOrder[a.codeName] - adherenceCodeOrder[b.codeName])
      return shortLegend
    }
  },
  methods: {
    getCalendarColor (code) {
      if (this.accessibilityModeState) {
        return this.accessibilityCodeToColorMap[code]
      }
      return this.getCodeToConfigMap[code].design.color
    },
    getDigitalCount (codes) {
      let i = 0
      if (Object.keys(this.getAdherenceData).length > 0) {
        for (const month of this.getAdherenceData.adherenceMonthWiseData) {
          for (const day of month.adherenceDataForCalendar) {
            if (codes === day.code) { i++ }
          }
        }
      }
      return i
    }
  }
}
</script>

<style scoped>
.calendar-legend p {
    color: var(--text-primary-color);
}
.calendar-legend {
    display: block;
    position: relative;
    text-align: center;
}
.legend-info:hover {
    background-color: var(--light-hover-color);
    cursor: pointer;
    transition: 0.3s;
}
.legend-info {
    padding: 5px;
    border-radius: 3px;
    width: 20%;
    display: inline-block;
    vertical-align: text-top;
    text-align: initial;
}
.legend-info div{
    float: left;
    margin-right: 5px;
    display: inline-block;
}
.legend-info p {
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 75%;
}
.legend-color {
  width:18px;
  height:18px;
  border-radius:3px;
  position: relative;
}
.legend-icon {
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    color: black;
}
</style>
