export const calendarDaySpeechBubbleMixin = {
  data () {
    return {
      leftCoordinates: 0,
      topCoordinates: 0,
      showSpeechBubble: false,
      speechBubbleHovering: false,
      lastPoints: {
        X: 0, Y: 0
      },
      speechBubbleItems: [],
      speechBubbleHeading: '',
      speechBubbleDoseType: '',
      timeouts: [],
      speechBubbleHeight: 0
    }
  },
  methods: {
    performDayMouseoverAction (event, point, day, dayData, monthData) {
      this.speechBubbleHovering = true
      this.speechBubbleHeading = this.toPascalCase(dayData.dayName) + ', ' + this.toPascalCase(monthData.monthName) + ' ' + day + ', ' + monthData.year
      this.speechBubbleItems = dayData.tagsForDay
      this.speechBubbleDoseType = dayData.code
      this.lastPoints = { X: point.pointX, Y: point.pointY, width: point.width }
      this.timeouts.push(setTimeout(() => {
        if (this.speechBubbleHovering) {
          this.showSpeechBubble = this.speechBubbleHovering
        }
      }, 500))
    },
    performDayMouseLeaveAction () {
      this.speechBubbleHovering = false
      this.showSpeechBubble = false
      this.timeouts.forEach(t => clearTimeout(t))
    }
  },
  watch: {
    showSpeechBubble: function (val) {
      if (val) {
        this.$nextTick(function () {
          var sbHeight = this.$refs.speech_bubble.$el.offsetHeight
          this.speechBubbleHeight = sbHeight
          this.leftCoordinates = this.lastPoints.X + this.lastPoints.width
          this.topCoordinates = this.lastPoints.Y - sbHeight / 2 + 17
        })
      }
    },
    speechBubbleHeight: function () {
      this.leftCoordinates = this.lastPoints.X + this.lastPoints.width
      this.topCoordinates = this.lastPoints.Y - this.speechBubbleHeight / 2 + 17
    }
  }

}
