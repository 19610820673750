<template>
    <div
    class="speech_bubble_container">
        <div class="speech_bubble">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
  name: 'SpeechBubble'
}
</script>

<style scoped>
.speech_bubble_container {
    position: absolute;
    margin-top: -4rem;
}
.speech_bubble {
  position: relative;
  border-radius: 10px;
  background-color: #1E234C;
  color:#D6D8E7;
  margin-left: 10px;
}
.speech_bubble::before {
  content: "";
  position: absolute;
  left: -14px;
  top:50%;
  margin-top: -5px;
  width: 0;
  transform: rotate(90deg);
  border-top: 10px solid #1E234C;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
</style>
