<template>
  <div
    :class="['day-container', getClass()]"
    :style="{
      'background-color': getCalendarColor(dayData.code)
    }"
    @click="$emit('day-click', day, dayData, monthData)"
    @mouseenter.ctrl="$emit('day-mouseover-ctrl', day, dayData, monthData)"
    @mouseenter.exact="$emit('day-mouseover', $event, getPoints(), day, dayData, monthData)"
    @mouseleave="$emit('day-mouseleave', $event, getPoints())"
    ref = "calendar_day">
    <div v-if="dayData.tagsForDay.length == 0">
      <font-awesome-icon v-if = "dayData.design != null && dayData.design !=undefined && dayData.design.icon !== null"
          class="day-icon"
          :icon = dayData.design.icon />
    </div>
    <div v-else>
      <font-awesome-icon
        class="day-icon"
        icon="ellipsis-h" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CalendarDay',
  props: {
    dayData: {},
    monthData: {},
    day: Number,
    firstDay: {
      type: Boolean,
      default: false
    },
    endDay: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getPoints () {
      var scrollTop = document.getElementById('main-page').scrollTop
      var scrollLeft = document.getElementById('main-page').scrollLeft
      return {
        width: this.$refs.calendar_day.getBoundingClientRect().width,
        pointX: this.$refs.calendar_day.getBoundingClientRect().left + scrollLeft,
        pointY: this.$refs.calendar_day.getBoundingClientRect().top + scrollTop
      }
    },
    getCalendarColor (code) {
      if (this.accessibilityModeState) {
        return this.accessibilityCodeToColorMap[code]
      }
      return this.getCodeToConfigMap[code].design.color
    },
    getClass () {
      var classList = []
      if (this.firstDay) {
        classList.push('start-gradient')
      }
      if (this.endDay) {
        classList.push('end-gradient')
      }
      if (this.accessibilityModeState) {
        for (var i in classList) {
          classList[i] = classList[i] + '-acc-mode'
        }
      }
      return classList
    }
  },
  computed: {
    ...mapGetters('Adherence', ['getCodeToConfigMap', 'accessibilityCodeToColorMap']),
    ...mapGetters(['darkModeState', 'accessibilityModeState'])
  }
}
</script>

<style scoped>

.start-gradient {
  background-image: linear-gradient(90deg, rgba(106,152,221,1) 50%, rgba(255,255,255,0) 50%);
}

.end-gradient {
  background-image: linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(106,152,221,1) 50%);
}

.start-gradient-acc-mode {
  background-image: linear-gradient(90deg, #009E73 50%, rgba(255,255,255,0) 50%);
}

.end-gradient-acc-mode {
  background-image: linear-gradient(90deg, rgba(255,255,255,0) 50%, #009E73 50%);
}

.day-container {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
    opacity: var(--theme-opacity);
}

.day-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    color: black;
}
</style>
